import { FC, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { OpenseaIcon } from "@dustlabs/web/icons";
import { GLOBAL_CONFIG } from "@shared/constants";
import {
  CloseIcon,
  DiscordIcon,
  TwitterIcon,
  InstagramIcon,
  NavItem,
  Backdrop,
  MagicEdenIcon,
  ColorBlockLogo,
  MoreMenuMobile,
  ArrowToggle,
} from "@shared/components";
import { AppConfig, ProjectConfig } from "@shared/types";
import { usePortalHandlers } from "@shared/hooks";
import { createPortal } from "react-dom";
import ReactFocusLock from "react-focus-lock";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const MobileMenu: FC<Props> = ({ isOpen, handleClose }: Props) => {
  const { ID, APPS, DISCORD, SITE_NAME, TWITTER, INSTAGRAM, OPENSEA } =
    GLOBAL_CONFIG as ProjectConfig;
  const appsInPages = APPS.filter(
    (app) =>
      process.env.NEXT_PUBLIC_PAGES?.includes(app.key) ||
      app.key === "bridge" ||
      app.key === "more" ||
      app.key === "events"
  );
  const y00tsView = ID === "y00ts";
  const isBrowser: boolean = usePortalHandlers(isOpen, handleClose);
  const [showMoreMobile, setShowMoreMobile] = useState<boolean>(false);

  if (!isBrowser) return <></>;

  return createPortal(
    <AnimatePresence initial={false} exitBeforeEnter={true}>
      {isOpen && (
        <Backdrop handleClose={handleClose} anchor="right">
          <ReactFocusLock className="top-0 fixed w-5/6 sm:w-7/12 md:w-5/12">
            <motion.div
              tabIndex={-1}
              className="overflow-auto flex flex-col bg-lightBackground y00ts:border shadow-lg"
              initial={{ x: window.innerWidth * 2 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.3 }}
              exit={{ x: window.innerWidth * 2 }}
              key="menu"
              onClick={(event) => event.stopPropagation()}
              style={{ height: window.innerHeight }}
            >
              <div className="py-6 px-8 y00ts:border-b flex justify-between items-center">
                <motion.button
                  className="text-lightFontPrimary outline-none"
                  onClick={handleClose}
                  whileTap={{ scale: 0.9 }}
                  transition={{ duration: 0.2 }}
                >
                  <CloseIcon />
                </motion.button>
                {y00tsView && <ColorBlockLogo />}
              </div>
              <div
                className="flex flex-col justify-between"
                style={{ minHeight: `calc(${window.innerHeight}px - 69px)` }}
              >
                <div className="flex flex-col pt-8">
                  <div className="h-full flex flex-col justify-between w-full">
                    <div className="flex flex-col items-start gap-6 px-8">
                      {appsInPages
                        .filter(
                          (app: AppConfig) =>
                            app.key !== "more" && !Boolean(app.hide)
                        )
                        .filter((app: AppConfig) => app.key !== "more")
                        .map((appConfig: AppConfig) => (
                          <NavItem
                            key={appConfig.label}
                            appConfig={appConfig}
                            showMore={false}
                            setShowMore={() => {}}
                          />
                        ))}
                    </div>
                    {appsInPages
                      .filter((app: AppConfig) => app.key === "more")
                      .map((appConfig: AppConfig) => (
                        <div key={appConfig.key}>
                          <div
                            className="w-full flex flex-row justify-between items-center cursor-pointer py-1 border-y px-4 mt-3"
                            onClick={() => setShowMoreMobile((prev) => !prev)}
                          >
                            <div className="flex items-center p-4">
                              {/* {appConfig.image(showMoreMobile)} */}
                              <span
                                className={`block text-sm whitespace-nowrap ml-2 select-none ${
                                  showMoreMobile ? "font-bold" : "fontPrimary"
                                }`}
                              >
                                {appConfig.label}
                              </span>
                            </div>

                            <ArrowToggle trigger={showMoreMobile} />
                          </div>
                          <div className="mt-3 mb-8 px-6">
                            {showMoreMobile && <MoreMenuMobile />}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex-col items-center gap-3 px-8 ">
                  <div className="flex items-center gap-5 px-3 pb-5">
                    <a href={DISCORD} target="_blank" rel="noreferrer">
                      <DiscordIcon />
                    </a>
                    <a href={TWITTER} target="_blank" rel="noreferrer">
                      <TwitterIcon />
                    </a>
                    <a href={INSTAGRAM} target="_blank" rel="noreferrer">
                      <InstagramIcon />
                    </a>
                    <a href={OPENSEA} target="_blank" rel="noreferrer">
                      <OpenseaIcon
                        width={24}
                        height={24}
                        outerColor="#000000"
                      />
                    </a>
                  </div>
                  <div className="text-xs text-center text-lightFontPrimary">
                    Get these apps for your community.{" "}
                    <a
                      href="https://share.hsforms.com/1j8d_YDFnQsaSHii6z6jkFwdjd9g"
                      target="_blank"
                      className="text-[#1B73DA]"
                      rel="noreferrer noopener"
                    >
                      Get Started
                    </a>
                  </div>
                  <div className="text-[10px] text-fontSecondary text-center mb-6">
                    © {new Date().getFullYear()} {SITE_NAME}. All rights
                    reserved.
                  </div>
                </div>
              </div>
            </motion.div>
          </ReactFocusLock>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.getElementById("modal-portal")!
  );
};

export default MobileMenu;
